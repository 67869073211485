body{
    background: black;
}

.background{
    position: fixed;
    opacity: 0.25;
    z-index: -999;
    background-image: url('./images/codeImage.png');
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    /* top layer */
    z-index: 9999;
    background: #222;
    color: white;
    border-right: 5px solid teal;
    box-shadow: 2px 2px 2px 0px rgb(0 0 0 / 20%);
}

#content{
    overflow: auto;
    margin-left: 250px;
    width:100%;
}

.sidebar-header{
    margin: 10px;
    margin-top: 40vh;
}

.sidebar-header h4{
    padding:10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #354040
}

.navigation-link{
    color: white;
    text-decoration: none;
    font-size: 14pt;
    font-weight: 500;
    display: block;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
}

.navigation-link.active{
    background: teal;
}

.navigation-link:hover{
    color: white;
    background: #2d2d2d;
}

#homeBlock img{
    width: 100%;
    height:100vh;
    object-fit: cover;
    overflow: hidden;
    opacity: 0.25;
}

#aboutBlock{
    background: white;
    padding: 10px 150px 100px 150px;
}

.about-group{
    position: relative;
}

.about-item{
    display: inline-block;
    max-width: 800px;
    vertical-align: top;
    margin: 30px;
}

#aboutBlock img{
    max-width: 400px;
    height:100%;
    object-fit: cover;
    overflow: hidden;
    box-shadow: 2px 2px 2px 0px rgb(0 0 0 / 20%);
}

.content-block {
    min-height: 100vh;
}

.content-block .block-title-center{
    position: absolute;
    z-index: 999;
    top: 40%;
    left: 40%;
    color: white;
}

.block-title-center h1{
    font-size: 60pt;
}

.block-title-center h3{
    color: #b6eeee;
}

.content-block-head-left{
    margin:10px;
    text-align: left;
    margin-top: 60px;
    margin-bottom: 40px;
    border-bottom: 1px solid #cecece;
}

.content-block-head-left h1{
    font-size: 40pt;
    font-weight: bold;
}

.content-block-head-left h3{
    font-size: 18pt;
}

.blurb{
    font-size: 16pt;
    font-weight: 500;
    padding: 10px;
    text-align: left;
}

.blurb .title{
    font-size: 26pt;
    text-align: left;
}

#skillsBlock{
    background: #f4f4f4;
    padding: 10px 150px 100px 150px;
}

.skill-card{
    position: relative;
    width: 450px;
    min-height: 60px;
    padding: 10px;
    border-radius: 4px;
}

.bar-wrapper{
    /* background: #fff; */
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    /* box-shadow: 2px 2px 2px 0px rgb(0 0 0 / 20%); */
}

.skill-card .label{
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;;
}

.skill-card .icon{
    width: 50px;
    height: 40px;
    border-radius: 4px;
}

.skill-card .icon img{
    width: 100%;
    height:100%;
    object-fit: cover;
    overflow: hidden;
}

.skill-card .bar-container{
    background: #222222;
    width: 100%;
    height: 40px;
    margin-left: 10px;
    border-radius: 4px;
}

.skill-card .title{
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 10px;
}


#skillsBlock .bar-wrapper{
    display: flex;
}

.bar-container .bar-fill{
    width: 5px;
    height: 80%;
    margin: 4px;
    background: #008080;
    border-radius: 2px;
}

#skillsBlock .skill-panel{
    display: inline-block;
    vertical-align: top;
    width: auto;
    margin-bottom:40px;
}

.skill-panel h3{
    text-align: center;
    color: #9d9d9d;
    font-weight: 400;
}

.others-panel.others-panel p{
    border-bottom: 1px solid #cecece;
}

.skill-panel.others-panel ul{
    list-style: none;
}

.skill-panel h3{
    width: 450px;
    text-align: center;
    margin-bottom:20px;
}

#skillWrapper{
    
}

.skill-card{
    text-align: left;
}

#polygonCard{
    position: relative;

    margin-left: 50px;
    background: #cecece;
}

.left-align{
    text-align: left;
}

.point{
    background: red;
    position: absolute;
}

#aboutBlock .col-8 .bio{
    padding: 10px;
}

#footerBlock{
    min-height: 300px;
    background: #fff;
    border-top: 1px solid #cecece;
    padding: 10px 150px 100px 150px;
}

#footerBlock #footerContent{
    margin-top: 10px;
}

#footerEmblem{
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-image: url(./images/VWLogo.png);
    background-size: contain;
    opacity: 0.25;
    position: relative;
    margin: auto;
}


@media screen and (max-width: 600px) {
    #sidebar {
      display: none;
    }

    #content {
      margin-left: 0px;  
    }

    .content-block .block-title-center {
        left: 0;
    }

    #aboutBlock {
        padding: 0;
    }

    #aboutBlock img {
        width: 100%;
    }

    #skillsBlock {
        padding: 10px 0px 100px 0px;
    }

    .skill-card {
        width: 90%;
    }

    .bar-container {
        overflow: hidden;
    }

    .others-panel ul{
        margin: 0;
        padding: 0;
    }

    .skill-panel h3 {
        margin-left: -24px;
    }

    #footerBlock {
        font-size: 10pt;
        padding:10px;
    }

    #footerContent {
        width: 100%;
    }
  }